import SummaryApi from "../common";
import { jwtDecode } from "jwt-decode";
//let baseURL = "http://127.0.0.1:8000";

let originalRequest = async (url, config, authTokens) => {
  //url = `${baseURL}${url}`

  console.log('config["body"]', config["body"]);

  let config2 = {
    method: config["method"],
    // body: JSON.stringify({username: "example"}),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authTokens?.access}`,
    },
  };

  if ("body" in config) {
    config2["body"] = config["body"];
  }

  //console.log("originalRequest", config);
  let response = await fetch(url, config2);
  let data = await response.json();
  //console.log("REQUESTING:", data);
  return { response, data };
};

let refreshToken = async (authTokens, setAuthTokens, setUser) => {
  const url = new URL(SummaryApi.tokenRefresh.url);
  //const refresh = authTokens.refresh;
  //url.searchParams.set("token", `${refresh}`);
  //console.log("SummaryApi.tokenRefresh.url", { refresh });

  //let {authTokens, setAuthTokens, setUser} = useContext(TokenContext)

  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ refresh: authTokens.refresh }),
  });
  let data = await response.json();
  if (response.status === 200) {
    localStorage.setItem("authTokens", JSON.stringify(data.data));
    setAuthTokens(data.data);
    setUser(jwtDecode(data.data.access));
    return data.data;
  } else {
    //localStorage.removeItem("authTokens");
    return null;
  }
};

let customFetcher = async (
  url,
  config = {},
  setAuthTokens,
  setUser,
  logoutUser
) => {
  //console.log("ctx : ", logoutUser);
  let authTokens = localStorage.getItem("authTokens")
    ? JSON.parse(localStorage.getItem("authTokens"))
    : null;

  //Proceed with request

  config["headers"] = {
    Authorization: `Bearer ${authTokens?.access}`,
    Accept: "application/json",
  };

  //config["body"] = config['body']
  //console.log("Before Request");
  //console.log(`Bearer ${authTokens?.access}`);
  //console.log("config", config);
  let { response, data } = await originalRequest(
    url,
    config,
    authTokens,
    setAuthTokens,
    setUser
  );
  //console.log("After Request");
  console.log("data : customFetcher", data);
  //if (response.statusText === "Unauthorized") {
  if (data.message === "Authentication is required to continue") {
    if (authTokens != null) {
      authTokens = await refreshToken(authTokens, setAuthTokens, setUser);

      config["headers"] = {
        Authorization: `Bearer ${authTokens?.access}`,
      };

      let newResponse = await originalRequest(url, config, authTokens);
      response = newResponse.response;
      data = newResponse.data;
      if (
        response.statusText === "Unauthorized" ||
        response.statusText === "Authentication is required to continue" ||
        response.status === 401
      ) {
        data = { message: "Session expired", error: true };
        logoutUser();
      }
    } else {
      //navigate('/', { replace: true });
      data = { message: "Session expired", error: true };
      logoutUser();
    }
  }

  return { response, data };
};
export default customFetcher;
