import React, { useContext, useEffect, useState } from "react";
import SummaryApi from "../common";
import { toast } from "react-toastify";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import {
  styled,
  useTheme,
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import ChartLinex from "../components/chartLinex";
import customFetcher from "../utils/fetchInstance";
import TokenContext from "../context/TokenContext";
import BasicDatePicker from "../components/datePicker/BasicDatePicker";
import Button from "@mui/material/Button";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import {
  blueberryTwilightPalette,
  mangoFusionPalette,
  cheerfulFiestaPalette,
} from '@mui/x-charts/colorPalettes';
import CssBaseline from '@mui/material/CssBaseline';

const Dashboard = () => {
  //const [lineGraphData, setLineGraphData] = useState([]);
  const [dataset, setDataset] = useState([]);
  let { authTokens, setAuthTokens, setUser, logoutUser } =
    useContext(TokenContext);

  const theme = useTheme();
  const [colorScheme, setColorScheme] = React.useState("blueberryTwilight");
  const [colorMode, setColorMode] = React.useState(theme.palette.mode);

  const newTheme = createTheme({ palette: { mode: colorMode } });

  /* pie chart */
  const data = [
    { value: 5, label: "A" },
    { value: 10, label: "B" },
    { value: 15, label: "C" },
    { value: 20, label: "D" },
  ];

  const size = {
    width: 400,
    height: 200,
  };

  const StyledText = styled("text")(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 20,
  }));

  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }

  /* pie chart */

  //let api = useFetch();

  const fetchLineGraphData = async () => {
    let url = SummaryApi.getUsersDataForGraph.url;
    let config = {
      method: SummaryApi.getUsersDataForGraph.method,
      // body: JSON.stringify({username: "example"}),
      headers: {
        "Content-Type": "application/json",
      },
    };
    //let { response, data } = await api(url, config);
    let { response, data } = await customFetcher(
      url,
      config,
      setAuthTokens,
      setUser,
      logoutUser
    );
    //console.log('response : ', response)
    //console.log("fetchLineGraphData : ", data);

    if (data.error) {
      toast.error(data.message);
    }

    if (data.success) {
      //setLineGraphData(data.data);
      setDataset(data.data);
    }
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  useEffect(() => {
    fetchLineGraphData();
    console.log(dataset);
  }, []);

  return (
    <>
      <ThemeProvider theme={newTheme}>
        <div className="mt-5">
          <BasicDatePicker />
        </div>

        <div className="mt-10">
          <ChartLinex dataset={dataset} />
        </div>

        <div className="mt-5">
          <div>
            <Button
              sx={{ ml: 1 }}
              onClick={() =>
                setColorMode((prev) => (prev === "light" ? "dark" : "light"))
              }
              color="inherit"
              endIcon={
                colorMode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />
              }
            >
              {colorMode} mode
            </Button>
          </div>

        </div>

        <CssBaseline />
        <main>This app is using the dark mode</main>

        <div className="mt-5">
          <PieChart series={[{ data, innerRadius: 80 }]} {...size}>
            <PieCenterLabel>Center label</PieCenterLabel>
          </PieChart></div>
      </ThemeProvider>
    </>
  );
};

export default Dashboard;
