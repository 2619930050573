/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from "react";
import SummaryApi from "../common";
import displayINRCurrency from "../helpers/displayCurrency";
import { MdDelete } from "react-icons/md";
import customFetcher from "../utils/fetchInstance";
import { toast } from "react-toastify";
import TokenContext from "../context/TokenContext";
import useFetch from "../utils/useFetch";

const Cart = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const loadingCart = new Array(4).fill(null);
  const { setCartProductCount, logoutUser, setAuthTokens, setUser } =
    useContext(TokenContext);
  const [dataClient, setDataClient] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });
  const [errors, setErrors] = useState({});

  const spanErrorStyle = {
    backgroundColor: "#dee3e7",
    color: "#e74c3c",
    fontsize: "14px",
    margintop: "5px",
    display: "block",
    padding: "3px",
  };

  //let api = useFetch();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setDataClient((preve) => {
      return {
        ...preve,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    //toast.error(dataClient.name);

    if (!dataClient.name.trim()) {
      validationErrors.name = "name is required";
    }

    /*
    if (!dataClient.email.trim()) {
      validationErrors.email = "email is required";
    } else if (!/\S+@\S+\.\S+/.test(dataClient.email)) {
      validationErrors.email = "email is not valid";
    }
    */

    if (!dataClient.address.trim()) {
      validationErrors.address = "address is required";
    } else if (dataClient.address.length < 10) {
      validationErrors.address = "address should be at least 10 char";
    }

    if (!dataClient.phone.trim()) {
      validationErrors.phone = "phone is required";
    } else if (dataClient.phone.length < 10) {
      validationErrors.phone = "phone should be at least 10 char";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      //alert("Form Submitted successfully");
      console.log(dataClient);

      let url = SummaryApi.checkoutCart.url;
      let config = {
        method: SummaryApi.checkoutCart.method,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(dataClient),
      };
      let { response, data } = await customFetcher(
        url,
        config,
        setAuthTokens,
        setUser,
        logoutUser
      );
      //console.log('response : ', response)

      const dataApi = data;

      if (!data.error) {
        //toast.success(data.message);
        console.log("Order confirmed -> : ", data.data);
        //redirect to page conformation order
      }

      if (data.error) {
        toast.error(data.message);
      }
    }
  };


  const fetchUserAddToCart2 = async () => {
    //console.log('call fetchUserAddToCart')
    let url = SummaryApi.addToCartProductCount.url;
    let method = SummaryApi.addToCartProductCount.method;
    let config = {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
    };
    //let { data } = await api(url, config);
    let { response, data } = await customFetcher(
      url,
      config,
      setAuthTokens,
      setUser,
      logoutUser
    );
    //console.log('response : ', response)
    console.log("fetchUserAddToCart2 : ", data);

    if (data.error) {
      toast.error(data.message);
    } else {
      const dataApi = data;
      setCartProductCount(dataApi?.data?.count);

    }

  };

  const fetchData = async () => {
    let url = SummaryApi.addToCartProductView.url;
    let config = {
      method: SummaryApi.addToCartProductView.method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    let { data } = await customFetcher(
      url,
      config,
      setAuthTokens,
      setUser,
      logoutUser
    );
    //console.log('response : ', response)

    if (!data.error) {
      //toast.success(data.message);
      console.log("data : addToCartProductView -> : ", data.data);
      setData(data.data);
      if (!data.data.length === 0) {
        console.log("data : addToCartProductView -> : ", data.data);
        setData(data.data);
      }
    }

    if (data.error) {
      toast.error(data.message);
    }
  };

  const handleLoading = async () => {
    await fetchData();
  };

  const deleteCartProduct = async (id) => {
    const postsData = {
      id: id,
    };

    let url = SummaryApi.deleteCartProduct.url;
    let config = {
      method: SummaryApi.deleteCartProduct.method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(postsData),
    };
    let { data } = await customFetcher(
      url,
      config,
      setAuthTokens,
      setUser,
      logoutUser
    );
    //console.log('response : ', response)

    if (!data.error) {
      //toast.success(data.message);
      console.log("deleteCartProduct  -> : ", data.data);
      fetchData();
      fetchUserAddToCart2();
    }

    if (data.error && data.message !== "Session expired") {
      toast.error(data.message);
    }
  };

  const increaseQty = async (id, qty) => {
    // const response = await fetch(SummaryApi.updateCartProduct.url, {
    //   method: SummaryApi.updateCartProduct.method,
    //   credentials: "include",
    //   headers: {
    //     "content-type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     _id: id,
    //     quantity: qty + 1,
    //   }),
    // });

    // const responseData = await response.json();

    // if (responseData.success) {
    //   fetchData();
    // }

    const postsData = {
      id: id,
      quantity: qty + 1,
    };

    let url = SummaryApi.updateCartProduct.url;
    let config = {
      method: SummaryApi.updateCartProduct.method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(postsData),
    };
    let { data } = await customFetcher(
      url,
      config,
      setAuthTokens,
      setUser,
      logoutUser
    );
    //console.log('response : ', response)

    if (!data.error) {
      //toast.success(data.message);
      console.log("data : decraseQty -> : ", data.data);
      fetchData();
    }

    if (data.error) {
      toast.error(data.message);
    }
  };

  const decraseQty = async (id, qty) => {
    if (qty >= 2) {
      const postsData = {
        id: id,
        quantity: qty - 1,
      };

      let url = SummaryApi.updateCartProduct.url;
      let config = {
        method: SummaryApi.updateCartProduct.method,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(postsData),
      };
      let { data } = await customFetcher(
        url,
        config,
        setAuthTokens,
        setUser,
        logoutUser
      );
      //console.log('response : ', response)

      if (!data.error) {
        toast.success(data.message);
        console.log("data : decraseQty -> : ", data.data);
        fetchData();
      }

      if (data.error) {
        toast.error(data.message);
      }
    }

    // if (qty >= 2) {
    //   const response = await fetch(SummaryApi.updateCartProduct.url, {
    //     method: SummaryApi.updateCartProduct.method,
    //     credentials: "include",
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       _id: id,
    //       quantity: qty - 1,
    //     }),
    //   });

    //   const responseData = await response.json();

    //   if (responseData.success) {
    //     fetchData();
    //   }
    // }
  };

  const totalQty = data.reduce(
    (previousValue, currentValue) => previousValue + currentValue.quantity,
    0
  );
  const totalPrice = data.reduce(
    (preve, curr) => preve + curr.quantity * curr?.sellingPrice,
    0
  );

  useEffect(() => {
    setLoading(true);
    handleLoading();
    setLoading(false);
  }, [setLoading]);

  return (
    <div className="container mx-auto">
      <div className="text-center text-lg my-3">
        {data.length === 0 && !loading && (
          <p className="bg-white py-5">No Data</p>
        )}
      </div>

      <div className="flex flex-col lg:flex-row gap-10 lg:justify-between p-4">
        {/***view product */}
        <div className="w-full max-w-3xl">
          {loading
            ? loadingCart?.map((el, index) => {
              return (
                <div
                  key={el + "Add To Cart Loading" + index}
                  className="w-full bg-slate-200 h-32 my-2 border border-slate-300 animate-pulse rounded"
                ></div>
              );
            })
            : data.map((product, index) => {
              return (
                <div
                  key={product?.id + "Add To Cart Loading"}
                  className="w-full bg-white h-32 my-2 border border-slate-300  rounded grid grid-cols-[128px,1fr]"
                >
                  <div className="w-32 h-32 bg-slate-200">
                    <img
                      src={product?.productImage}
                      className="w-full h-full object-scale-down mix-blend-multiply"
                    />
                  </div>
                  <div className="px-4 py-2 relative">
                    {/**delete product */}
                    <div
                      className="absolute right-0 text-red-600 rounded-full p-2 hover:bg-red-600 hover:text-white cursor-pointer"
                      onClick={() => deleteCartProduct(product?.id)}
                    >
                      <MdDelete />
                    </div>

                    <h2 className="text-lg lg:text-xl text-ellipsis line-clamp-1">
                      {product?.productName}
                    </h2>
                    <p className="capitalize text-slate-500">
                      {product?.categoryName}
                    </p>
                    <div className="flex items-center justify-between">
                      <p className="text-red-600 font-medium text-lg">
                        {displayINRCurrency(product?.sellingPrice)}
                      </p>
                      <p className="text-slate-600 font-semibold text-lg">
                        {displayINRCurrency(
                          product?.sellingPrice * product?.quantity
                        )}
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-1">
                      <button
                        className="border border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded "
                        onClick={() =>
                          decraseQty(product?.id, product?.quantity)
                        }
                      >
                        -
                      </button>
                      <span>{product?.quantity}</span>
                      <button
                        className="border border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded "
                        onClick={() =>
                          increaseQty(product?.id, product?.quantity)
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        {/***summary  */}
        {data[0] && (
          <div className="mt-1 lg:mt-0 w-full max-w-sm">
            {loading ? (
              <div className="h-36 bg-slate-200 border border-slate-300 animate-pulse"></div>
            ) : (
              <div className="h-56 bg-white">
                <h2 className="text-white bg-red-600 px-4 py-1">Summary</h2>
                <div className="flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600">
                  <p>Quantity</p>
                  <p>{totalQty}</p>
                </div>

                <div className="flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600">
                  <p>Total Price</p>
                  <p>{displayINRCurrency(totalPrice)}</p>
                </div>
                {/* <Info client"> */}
                <div className="mt-1 h-56 bg-white">
                  <h2 className="text-white bg-green-600 px-4 py-1">
                    Client info
                  </h2>
                  <div className="mt-3 lg:mt-0 w-full max-w-sm">
                    <div className="bg-white p-5 w-full max-w-sm mx-auto">
                      <form
                        className="flex flex-col gap-2"
                        onSubmit={handleSubmit}
                      >
                        <div className="grid">
                          <label>Name : </label>
                          <div className="bg-slate-100 p-4">
                            <input
                              type="text"
                              placeholder="enter your name"
                              name="name"
                              value={dataClient.name}
                              onChange={handleOnChange}
                              // required
                              className="w-full h-full outline-none bg-transparent"
                            />
                            {errors.name && (
                              <span style={spanErrorStyle}>{errors.name}</span>
                            )}
                          </div>
                        </div>

                        {/* 
                      <div className="grid">
                        <label>Email : </label>
                        <div className="bg-slate-100 p-2">
                          <input
                            type="email"
                            placeholder="enter email"
                            name="email"
                            value={dataClient.email}
                            onChange={handleOnChange}
                            //required
                            className="w-full h-full outline-none bg-transparent"
                          />
                          {errors.email && <span style={spanErrorStyle}>{errors.email}</span>}
                        </div>
                      </div> 
                      */}
                        <div className="grid">
                          <label>Address : </label>
                          <div className="bg-slate-100 p-4">
                            <input
                              type="text"
                              placeholder="enter your address"
                              name="address"
                              value={dataClient.address}
                              onChange={handleOnChange}
                              //required
                              className="w-full h-full outline-none bg-transparent"
                            />
                            {errors.address && (
                              <span style={spanErrorStyle}>
                                {errors.address}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="grid">
                          <label>Phone number : </label>
                          <div className="bg-slate-100 p-4">
                            <input
                              type="text"
                              placeholder="enter phone number"
                              name="phone"
                              value={dataClient.phone}
                              onChange={handleOnChange}
                              //required
                              className="w-full h-full outline-none bg-transparent"
                            />
                            {errors.phone && (
                              <span style={spanErrorStyle}>{errors.phone}</span>
                            )}
                          </div>
                        </div>
                        <button className="bg-red-600 hover:bg-red-700 text-white px-6 py-2 w-full max-w-[150px] rounded-full hover:scale-110 transition-all mx-auto block mt-6">
                          Buy now
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                {/* </section> */}
                {/* <button className="bg-blue-600 p-2 text-white w-full mt-2">
                Payment
              </button> */}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
