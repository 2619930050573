import React from "react";
import { LineChart } from "@mui/x-charts";

const ChartLinex = ({ dataset = [] }) => {
  //console.log('dataset', dataset)
  let xLabels = [];
  let pData = [];

  for (const [key, value] of Object.entries(dataset)) {
    //console.log(`${key}: ${value}`);
    let y = value.y
    let x = value.x
    xLabels.push(x)
    pData.push(y)
  }

  return (
    <LineChart
      //dataset={data2}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
      series={[
        { data: pData, label: 'Created users' },
      ]}
      height={400}
      margin={{ left: 30, right: 30, top: 30, bottom: 30 }}
      grid={{ vertical: true, horizontal: true }}
    />
  );
};

export default ChartLinex;
