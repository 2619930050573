import { createContext, useState, useEffect, useContext } from "react";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../store/userSlice";
import SummaryApi from "../common";
import TokenContext from "./TokenContext";
import customFetcher from "../utils/fetchInstance";
import { toast } from "react-toastify";

const AppContext = createContext(null);
export default AppContext;

export const AppProvider = ({ children }) => {
  const { cartProductCount, setCartProductCount } = useContext(TokenContext);
  const dispatch = useDispatch();
  let [loading, setLoading] = useState(true);
  let { authTokens, setAuthTokens, setUser, logoutUser } = useContext(TokenContext);

  //let api = useFetch();

  const fetchUserDetails = async () => {
    let url = SummaryApi.current_user.url;
    let config = {
      method: SummaryApi.current_user.method,
      // body: JSON.stringify({username: "example"}),
      headers: {
        "Content-Type": "application/json",
      },
      // credentials: 'include',
    };
    //let { response, data } = await api(url, config);
    let { response, data } = await customFetcher(
      url,
      config,
      setAuthTokens,
      setUser,
      logoutUser
    );
    //console.log('response : ', response)
    console.log("data : ", data);

    const dataApi = data; //await data.json();
    //console.log('dataResponse : ', dataResponse)
    if (data.error) {
      toast.error(data.message);
    }
    if (dataApi.success) {
      //console.log('dataApi : ', dataApi)
      dispatch(setUserDetails(dataApi.data));
    }
  };

  const fetchUserAddToCart = async () => {
    //console.log('call fetchUserAddToCart')
    let url = SummaryApi.addToCartProductCount.url;
    let method = SummaryApi.addToCartProductCount.method;
    let config = {
      method: method,
      // body: JSON.stringify({username: "example"}),
      headers: {
        "Content-Type": "application/json",
      },
    };
    // const dataResponse = await fetch(url, {
    //     method: SummaryApi.addToCartProductCount.method,
    //     credentials: "include",
    // });

    //let { response, data } = await api(url, config);
    let { response, data } = await customFetcher(
      url,
      config,
      setAuthTokens,
      setUser,
      logoutUser
    );
    //console.log('response : ', response)
    console.log("fetchUserAddToCart : ", data);

    if (data.error || response.status === 401) {
      //toast.error(data.message);
      logoutUser();
    }

    if (data.success) {
      setCartProductCount(data?.data?.count);
    }

  };

  let contextData = {
    setCartProductCount: setCartProductCount,
    cartProductCount: cartProductCount, // current user add to cart product count,
    fetchUserDetails: fetchUserDetails, // user detail fetch
    fetchUserAddToCart: fetchUserAddToCart,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwtDecode(authTokens.access));
      /**user Details */
      console.log("useEffect : AppProvider ", authTokens.access);
      fetchUserDetails();
      /**user Details cart product */
      fetchUserAddToCart();
    }
    setLoading(false);
  }, [loading]);

  return (
    <AppContext.Provider value={contextData}>
      {loading ? null : children}
    </AppContext.Provider>
  );
};
